import jwt from "jwt-decode"

const url = window.location.host === "myplantspace.com" ? "myplantspace.com":"http://localhost:5000"


export const registerUser = async(data, setEmailError)=>{
    try{
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }
        const response = await fetch(`${url}/authentication/register`, config)
        const json = await response.json()
        if (response.ok) {
            window.location.pathname = "/home"
            console.log(json)
            localStorage.setItem("token", json.token);
            //return json
            return response
        } else {
             setEmailError(true);
        }
    }catch(e){
        console.log(e)
    }
}

export const loginUser = async(data, setEmailError)=>{
    try{
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }
        const response = await fetch(`${url}/authentication/login`, config)
        const json = await response.json()
        if (response.ok) {
            window.location.pathname = "/profile"
            localStorage.setItem("token", json.token);
            return response
        } else {
             setEmailError(true);
        }
    }catch(e){
        console.log(e)
    }
}

export const checkAuthenticated = async()=>{
    try{
         const config = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': localStorage.getItem("token")
            },
            
        }
        const response = await fetch(`${url}/authentication/verify`, config)
        const json = await response.json()
        if (response.ok) {
          console.log(json)
            return json == true? null: localStorage.removeItem("token")
            //return json
        } 
    }catch(e){
      console.error(e.message)
    }
}
export const checkToken = ()=>{
    if(localStorage.token){
      try{
        //It decodes the token. It allows you to see the payload.
        const jwtToken = jwt(localStorage.token)
        //This check if the token is expired
        if(Date.now() > jwtToken.exp *1000){
          console.log("expired token")
          localStorage.removeItem("token")
        }else{
          //Token is not expired.
          return jwt(localStorage.token)
        }
      }catch(e){
        localStorage.removeItem("token")
        return false
      }
    }
    localStorage.removeItem("token")
    return false
}
export const setToken = (setUser)=>{
   if(localStorage.token){
    setUser(jwt(localStorage.token))
   }
   return false
}
