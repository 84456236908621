import { Outlet, Link } from "react-router-dom";
import './Navbar.scss'
import Logo from './../Home/Logo/Logo.png'

const Navbar = () => {
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to="/"><img src={Logo} alt="logo" width="300px" height="200px"/></Link>
          </li>
          <li>
            <Link to="/Profile">Profile</Link>
          </li>
          <li>
            <Link to="/Register">Register</Link>
          </li>
          
          <li>
            <Link to="/Contact">Contact</Link>
          </li>
          <li>
            <Link to="/Login"> Login</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Navbar;
