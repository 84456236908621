import React,{useEffect, useState, useContext} from 'react'
import { getSingleProfile } from '../../Services/Profile';
import {UserContext} from "../../Context/UserContext"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AddProfile from './AddProfile';
import EditProfile from './EditProfile';
import { deleteProfile } from '../../Services/Profile';
import Profilesass from '../Profile/Profilesass.scss';

const Profile = () => {
  const [profileData, setProfileData] = useState([]);
  const [singleProfileData, setSingleProfileData] = useState({});
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);
   const user = useContext(UserContext);
   //User contains id and username from payload.
    useEffect(()=>{
    getSingleProfile(user.id, setProfileData);
    },[])
  // console.log(singleProfileData)
   const deleteSingleProfile = (id) => {
   const confirmDelete=window.confirm("Are you sure you want to delete the plant?")
   if(confirmDelete){
    deleteProfile(id)
    setProfileData(profileData.filter(item => item.id !== id));
   }
   };
   console.log(singleProfileData, "singleProfileData")
  return (
    <>
    <h1>Profile</h1>
    <button onClick={handleShow}>Add a Plant</button>
    <AddProfile show={show}
        handleClose={handleClose}
        user={user}
        profileData={profileData}
        setProfileData={setProfileData}
        />
<EditProfile showEdit={showEdit}
        handleEditClose={handleEditClose}
        user={user}
        singleProfileData={singleProfileData}
        setSingleProfileData={setSingleProfileData}
        />
    {
      profileData.map((profile)=>{
        return(
          <>
          <br/>
           <img src={profile.photo} width={250} height={250} alt="Plant Photo" />
            <p>{profile.plant_name}</p>
            <p>{profile.light_cycle}</p>
            <p>{profile.water_cycle}</p>
            <p>{profile.geography}</p>
            <p>{profile.feeding_cycle}</p>
            <p>{profile.food_name}</p>
            <p>{profile.upcoming_watering}</p>
            <p>{profile.upcoming_feeding}</p> 
            <button onClick={() => {
          handleEditShow();
          setSingleProfileData(profile);
        }}>Edit</button>
        <button onClick={()=>deleteSingleProfile(profile.id)}>Delete</button>
           </>
        )
      })
    }
    </>
  )
}

export default Profile

//useEffect runs the functions when the page loads.
//1. useEffect 
//2. In the useEffect, make an API call. 
//3. Create a state.
//4. Pass setState to the API call. 
//5. The setState will contain the API's data.
//6. The data will be displayed using .map. 
