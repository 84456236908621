import "./Login.scss"
import { useForm } from "react-hook-form";
import {loginUser} from "../../Services/Auth.js"
import React, {useState} from "react";

const Login = () => { 
  const [emailError, setEmailError] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => {
    loginUser(data, setEmailError)
    console.log(data);
  }
  
  return (
    <>
    {emailError && <p>Invalid email or password.</p>} 
    
    <form onSubmit={handleSubmit(onSubmit)}>
       <label>
        Email:
      <input {...register("email", { required: true })} />
      </label>
      {errors.email && <span>This field is required</span>}
       <label>
        Password:
      <input type="password" {...register("password", { required: true, minLength: {
        value: 5,
        message: "Password must have at least 5 characters"
      } })} />
      </label>
      
      {errors.password && <span>Password must have at least 5 characters</span>}
    
      <input type="submit" />
    </form>
    </>
  )
}

export default Login