import React from 'react'

const NoPage = () => {
  return (
    <div>
      For any inquiries, send an e-mail to alecknipe@gmail.com
    </div>
  )
}

export default NoPage
