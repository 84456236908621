/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
const url = window.location.host == 'myplantspace.com' ? 'https://optimize-backend.nrg.com' : 'http://localhost:5000'; 
// Root url
axios.defaults.baseURL = url;

// Handle 400 & 500 errors
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.error(error);
  }

  return Promise.reject(error);
});

// Request interceptors for API calls
axios.interceptors.request.use(
  config => {
    config.headers['token'] = `${localStorage.getItem('token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Set X Auth in outbound headers
function setJwt(jwt) {
  axios.defaults.headers.common["token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
