import React, { useMemo, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm, Controller } from "react-hook-form";
import { editProfile } from "../../Services/Profile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";

const EditProfile = ({
	showEdit,
	handleEditClose,
	singleProfileData,
	setSingleProfileData,
	user,
	image,
}) => {
	const {
		plant_name,
		light_cycle,
		water_cycle,
		feeding_cycle,
		food_name,
		geography,
		upcoming_feeding,
		upcoming_watering,
	} = singleProfileData;
	const {
		register,
		handleSubmit,
		watch,
		control,
		reset,
		formState: { errors },
	} = useForm();
//   useEffect(() => {
//     //resets the data once its loaded to populate the form
//     if (singleProfileData) {
//       upcoming_watering ? upcoming_watering = new Date(upcoming_watering) : null;
//     }

// }, [singleProfileData]);
var date = new Date(upcoming_feeding);
// add a day
date.setDate(date.getDate() + 1);

console.log(date);

	const onSubmit = (data) => {
		data.user_id = user.id;
		data.id = singleProfileData.id;
		  data.upcoming_watering = data.upcoming_watering.toISOString().split('T')[0]
		  data.upcoming_feeding = data.upcoming_feeding.toISOString().split('T')[0]
		console.log(singleProfileData);
		console.log(data, "data");
		editProfile(data, singleProfileData);
	};
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setSingleProfileData({ ...singleProfileData, [name]: value });
	};

	return (
		<Modal
			show={showEdit}
			onHide={handleEditClose}
			backdrop='static'
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>Edit Plant</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit(onSubmit)}>
					<label htmlFor='plant_name'>
						Plant Name
						<br />
						<input
							{...register("plant_name", { required: true })}
							value={plant_name}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					{errors.plant_name && <span>This field is required</span>}
					<img src={singleProfileData.photo} width={50} height={50} alt='Plant' />
					<label htmlFor='photo'>
						Photo
						<br />
						<input
							{...register("photo")}
							type='file'
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<label htmlFor='light_cycle'>
						Light Cycle
						<br />
						<input
							{...register("light_cycle")}
							value={light_cycle}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<label htmlFor='water_cycle'>
						Water Cycle
						<br />
						<input
							{...register("water_cycle")}
							value={water_cycle}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<label htmlFor='geography'>
						Geography
						<br />
						<input
							{...register("geography")}
							value={geography}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<label htmlFor='feeding_cycle'>
						Feeding Cycle
						<br />
						<input
							{...register("feeding_cycle")}
							value={feeding_cycle}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<label htmlFor='food_name'>
						Food Name
						<br />
						<input
							{...register("food_name")}
							value={food_name}
							onChange={handleInputChange}
						/>
					</label>
					<br />

					<label htmlFor='upcoming_watering'>
						Upcoming Watering
						<br />
						<Controller
							className='mt-1'
							control={control}
							name='upcoming_watering'
							defaultValue={new Date(upcoming_watering)}
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<DatePicker
									dateFormat='yyyy-MM-dd'
									onChange={onChange}
									onBlur={onBlur}
									selected={new Date(value)}
								/>
							)}
						/>
					</label>
					<br />
					<label htmlFor='upcoming_feeding'>
						Upcoming Feeding
						<br />
						<Controller
							className='mt-1'
							control={control}
							name='upcoming_feeding'
							defaultValue={new Date(upcoming_feeding)}
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<DatePicker
									dateFormat='yyyy-MM-dd'
									onChange={onChange}
									onBlur={onBlur}
									selected={new Date(value)}
								/>
							)}
						/>
					</label>
					<br />
					<Button variant='secondary' onClick={handleEditClose}>
						Close
					</Button>
					<Button onClick={handleEditClose} type='submit' variant='primary'>
						Submit
					</Button>
				</form>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
};

export default EditProfile;