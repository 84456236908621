import ReactDOM from "react-dom/client";
import React, {useState, useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import NoPage from "./Components/NoPage/NoPage";
import Profile from "./Components/Profile/Profile"
import Register from "./Components/Register/Register";
import Login from "./Components/Login/Login";
import { UserContext } from "./Context/UserContext";
import { checkAuthenticated, checkToken, setToken } from "./Services/Auth";
import 'bootstrap/dist/css/bootstrap.min.css';

checkAuthenticated()
export default function App() {
  const [user, setUser] = useState(checkToken());
  useEffect(() => {
    setToken(setUser)
  }, []);
  return (
    <>
    <UserContext.Provider value = {user}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="*" element={<NoPage />} />
          <Route path="Register" element={<Register />} />
          <Route path="Login" element={<Login />} />

        </Route>
      </Routes>
    </BrowserRouter>
    </UserContext.Provider>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);