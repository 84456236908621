import { UserContext } from "../Context/UserContext"
import http from "./http"
import S3FileUpload from "react-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;
const config = {
	bucketName: process.env.REACT_APP_bucketName,
	region: process.env.REACT_APP_region,
	secretAccessKey: process.env.REACT_APP_secretAccessKey,
	accessKeyId: process.env.REACT_APP_accessKeyId,
	dbURL: process.env.REACT_APP_dbURL,
};

export const getSingleProfile = async(id, setProfileData)=>{
  
  try{
    const profile = await http.get(`/profile/getprofile/${id}`)
      setProfileData(profile.data)
      
    return profile.data
  }catch(e){
    console.log(e)
  }
}
export const postProfile = async(data, setProfileData, profileData)=>{
  
  try{
    // const profile = await http.post("/profile", data)
    // console.log(profile)
    // data.id = profile.data.insertId
    // console.log(data)
    // setProfileData(profileData => [...profileData, data]);
    //   //setProfileData
    // return profile
    
  const image = data.photo[0];
  S3FileUpload.uploadFile(image, config)
    .then((info) => {
      data.photo = info.location
      const newProfile = http.post(`/profile`, data);
      return newProfile;
    })
    .then((backendData) => {
      data.id = backendData.data.insertId
      console.log(profileData, "profileData")
      setProfileData(profileData => [...profileData, data]);
    })
    .catch((err) => console.error(err));
  }catch(e){
    console.log(e)
  }
}
export const editProfile = async(data, singleProfileData)=>{
  
  try{
    //const editProfile = await http.put("/profile", data)
    const image = data.photo[0];
    if(image !== undefined) {
      console.log("image", image)
      S3FileUpload.uploadFile(image, config)
      .then((info) => {
        data.photo = info.location
        const newProfile = http.put(`/profile`, data);
        return newProfile;
      })
      .then((backendData) => {
        console.log(backendData, "backendData");
        window.location.reload();
      })
      .catch((err) => console.error(err));
    }else {
      console.log("noImage", image)
      data.photo = singleProfileData.photo
      const editProfile = await http.put("/profile", data)
      
      window.location.reload();
      return editProfile;
    }
  }catch(e){
    console.log(e)
  }
}
export const deleteProfile = async(id)=>{
  
  try{
    const deleteProfile = await http.delete(`/profile/${id}`)
      //setProfileData
    return deleteProfile.data
  }catch(e){
    console.log(e)
  }
}