import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useForm, Controller } from "react-hook-form";
import { postProfile } from '../../Services/Profile';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 

const AddProfile = ({show, handleClose, profileData, setProfileData, user}) => {
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm();
    const addProfile =(data)=> {
      postProfile(data, setProfileData, profileData)
      
      
    }
    const onSubmit = data => {
     data.user_id = user.id
     data.upcoming_watering = data.upcoming_watering.toISOString().split('T')[0]
     data.upcoming_feeding = data.upcoming_feeding.toISOString().split('T')[0]

     console.log(data);
     addProfile(data);
    }
  return (
    <Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Add a Plant</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    
    <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="plant_name">
            Plant Name
            <br />
            <input {...register("plant_name", { required: true })} />
        </label>
      <br />
      {errors.plant_name && <span>This field is required</span>}
      <label htmlFor="photo">
            Photo
            <br />
            <input {...register("photo")} type="file" />
      </label>
      <br />
      <label htmlFor="light_cycle">
            Light Cycle
            <br />
            <input {...register("light_cycle")} />
      </label>
      <br />
      <label htmlFor="water_cycle">
            Water Cycle
            <br />
            <input {...register("water_cycle")} />
      </label>
      <br />
      <label htmlFor="geography">
            Geography
            <br />
            <input {...register("geography")} />
      </label>
      <br />
      <label htmlFor="feeding_cycle">
            Feeding Cycle
            <br />
            <input {...register("feeding_cycle")} />
      </label>
      <br />
      <label htmlFor="food_name">
            Food Name
            <br />
            <input {...register("food_name")} />
      </label>
      <br />
      <label htmlFor="upcoming_watering">
            Upcoming Watering
            <br />
            {/* <input {...register("upcoming_watering")} /> */}
            <Controller
                                    className='mt-1'
                                    control={control}
                                    name='upcoming_watering'
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <DatePicker
                                           dateFormat="yyyy-MM-dd" 
                                           onChange={onChange}
                                           onBlur={onBlur}
                                          selected={value}
                                        />
                                    )}
                                />
                                {errors.upcoming_watering && (
                                    <span className='red'>This field is required</span>
                                )}
      </label>
      <br />
      <label htmlFor="upcoming_feeding">
            Upcoming Feeding
            <br />
            <Controller
                                    className='mt-1'
                                    control={control}
                                    name='upcoming_feeding'
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                        />
                                    )}
                                />
      </label>
      <br />
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button onClick={handleClose} type="submit" variant="primary">Submit</Button>
    </form>
    </Modal.Body>
    <Modal.Footer>
      
    </Modal.Footer>
  </Modal>
  )
}

export default AddProfile