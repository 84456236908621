import React, {useContext} from 'react';
import {UserContext} from "../../Context/UserContext";
import './Home.scss';
import Logo from '../Home/Logo/Logo.png';




const Home = () => {
  const user = useContext(UserContext);
  console.log(user)
  return (
    <div>
      {user.username}
    
      
      <h1>Welcome to My Plant Space!</h1>

      <p>Make a free profile. Share your plants with the world.</p>

      <br></br>

      <br></br>

      <h2>A space for your plants!</h2>

      <p>Here at My Plant Space, you can add your plants to your profile page, as well as keep track of their feeding and watering cycle.</p>

      <br></br>

      <img src="http://clipart-library.com/data_images/350009.png"></img>

     </div>
    


  )
}

export default Home
